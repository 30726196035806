import React, { useEffect, useState } from 'react';
import { Col, FormFeedback, Row, Spinner } from 'reactstrap';
import { Accept, useDropzone } from 'react-dropzone';
import { sizeValidator } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import { ImagePreview } from '../../../components/ImagePreview/ImagePreview';
import ImageViewer from 'react-simple-image-viewer';

type UploadProps = {
  productPictures: productPictures;
  accept: Accept;
  formik: any;
  images: string[];
};

type productPictures = {
  maxSize: number;
  maxFiles: number;
  error: string;
  onUpload: (image: File, setLoading: (state: boolean) => void) => Promise<void>;
  onDelete: (filename: string, setDeleting: (state: boolean) => void) => Promise<void>;
};

const UploadImage = ({productPictures, formik, accept, images} : UploadProps) => {
  const {
    maxSize: maxSizeProfile,
    maxFiles: maxFilesProfile,
    error: errorProfile,
    onUpload: onUpload,
    onDelete: onDelete,
  } = productPictures;

  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [profileFiles, setProfileFiles] = useState<string[]>([]);
  const jointDisabled = isUploading || isRemoving;
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (images.length) {
      setProfileFiles(images);
    }
  }, [images]);

  const profileIsDisabled = jointDisabled || profileFiles.length === 12;

  const { getRootProps: getProfileRootProps, getInputProps: getProfileInputProps } = useDropzone({
    accept,
    maxFiles: maxFilesProfile,
    disabled: profileIsDisabled,
    validator: (file) => sizeValidator(file, maxSizeProfile),
    onError: (err) => toastr.error(err.name, err.message),
    onDrop: async (acceptedFiles) => {
      await onUpload(acceptedFiles[0], setIsUploading).then((response) => {
        const image = response as unknown as {uploadedFile: string};

        if (!image) {
          return null;
        }

        setProfileFiles([...profileFiles, image.uploadedFile])
      });
    },
  });

  const openImageViewer = (url: string, profilePics: boolean) => {
    let imagesUrls: string[] = profileFiles.map((image: string) => decodeURI(image));

    setImagesUrls(imagesUrls);
    setCurrentImage(imagesUrls.indexOf(decodeURI(url)));
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return(
    <>
      {isViewerOpen && (
        <ImageViewer
          src={imagesUrls}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0 ,0.7)',
            zIndex: '31',
          }}
        />
      )}

      <div {...getProfileRootProps({ className: `h-100 upload-zone ${profileIsDisabled ? 'disabled' : ''} ${errorProfile ? 'border-danger' : ''}` })}>
        <input {...getProfileInputProps({ className: 'dropzone', disabled: profileIsDisabled })} />
        <Col>
          {<p className="text-center">Drag 'n' drop images here, or click to select them. Maximum {maxFilesProfile} images</p>}
          {(isUploading || isRemoving) && (
            <Row className="success-label justify-content-center align-items-center">
              <Col xs={8} md={3} className="loading d-flex flex-column align-items-center">
                <Spinner width={20} color="secondary" />
                <div className="sr-only">{isUploading ? 'Uploading...' : 'Removing...'}</div>
              </Col>
            </Row>
          )}

          {errorProfile && (
            <FormFeedback className="d-block text-center" invalid>
              <h5>{errorProfile}</h5>
            </FormFeedback>
          )}
        </Col>
      </div>

      <Row>
        {!!profileFiles.length && (
          <Row className="pt-4 preview-box">
            {profileFiles.map((file) => (
              <ImagePreview
                key={file}
                src={file}
                alt={file}
                colWidth={{ xs: 12, md: 6, lg: 3 }}
                onImageClick={(e) => openImageViewer((e.target as HTMLInputElement).src, false)}
                onDelete={async () => {
                  await onDelete(file, setIsRemoving);
                  const images = profileFiles.filter(i => i !== file);
                  setProfileFiles(images)
                }}
                disabled={false}
                imgClassName={'product-image'}
              />
            ))}
          </Row>
        )}
      </Row>
    </>

  )
}


export default UploadImage
